package ch.randm.playsit.frontend.element

import cats.implicits.{catsSyntaxOptionId, toFunctorOps, toShow}
import ch.randm.playsit.core.model.ElectronicAddress.ElectronicAddressType.{Email, Website}
import ch.randm.playsit.core.model.common.Text.TextType.Markdown
import ch.randm.playsit.core.model.common._
import ch.randm.playsit.core.model.{ElectronicAddress, Engagement}
import com.raquo.laminar.api.L._
import com.raquo.laminar.nodes.ReactiveElement
import typings.leaflet.{mod => leaflet}

import java.time.ZoneId
import java.time.format.DateTimeFormatter

case class EngagementElement(engagement: Persisted[Engagement], isFirst: Boolean) {

  private val id         = engagement.id.value.toString
  private val value      = engagement.get
  private val mapId      = s"map-$id"
  private val headerId   = s"header-$id"
  private val collapseId = s"collapse-$id"

  private def getTimeAndFormat(tt: Engagement.TimeType, format: String) =
    value.times.get(tt).map(_.atZone(ZoneId.of("UTC")).format(DateTimeFormatter.ofPattern(format)))

  private val header =
    h4(
      idAttr    := headerId,
      className := "accordion-header",
      button(
        typ                   := "button",
        className             := "accordion-button",
        dataAttr("bs-toggle") := "collapse",
        dataAttr("bs-target") := s"#$collapseId",
        aria.expanded         := isFirst,
        aria.controls         := collapseId,
        i(className := "bi-pin"),
        nbsp,
        nbsp,
        nbsp,
        em(getTimeAndFormat(Engagement.TimeType.StageTime, "ccc, dd.MM.yyyy")),
        nbsp,
        "—",
        nbsp,
        strong(value.name),
        nbsp,
        "—",
        nbsp,
        span(value.venue.get.show)
      )
    )

  private val flyer =
    value.flyer.map { f =>
      div(
        className := "col-md-4",
        img(
          src       := s"/proxy/asset/download/${f.get.file.path}",
          className := "img-thumbnail rounded float-start",
          alt       := f.get.description.map(_.value).getOrElse(value.name)
        )
      )
    }

  private val doors =
    getTimeAndFormat(Engagement.TimeType.DoorsOpen, "HH:mm").map { d =>
      tr(
        th("Doors"),
        td(d)
      )
    }

  private val stageTime =
    getTimeAndFormat(Engagement.TimeType.StageTime, "HH:mm").map { d =>
      tr(
        th(cls := "w-25", doors.as("Playtime").getOrElse("Time").asInstanceOf[String]),
        td(d)
      )
    }

  private val ticketPrice =
    value.ticketSales.map(_.get).minByOption(_.amount).map { ts =>
      tr(
        th("Price"),
        td(s"${ts.currency.code} ${"%.2f".format(ts.amount).replace(".00", ".--")}")
      )
    }

  private val tickets =
    value.ticketSales
      .map(_.get)
      .filter(_.salePoint.isRight)
      .minByOption(_.amount)
      .flatMap(_.salePoint.toOption.map(_.get))
      .flatMap {
        case ElectronicAddress(Email, v)   => a(href := s"mailto:$v", v).some
        case ElectronicAddress(Website, v) => a(href := v, target := "blank", v).some
        case _                             => None
      }
      .map { link =>
        tr(
          th("Buy Tickets"),
          td(link)
        )
      }

  private def initMap(): Unit = {
    val coords = engagement.flatMap(_.venue).flatMap(_.address).map(_.coordinates)
    coords.toOption.foreach {
      case (x, y) =>
        val latLng = leaflet.latLng(x, y)
        val map    = leaflet.map(mapId, leaflet.MapOptions().setZoom(15).setCenter(latLng))
        leaflet
          .tileLayer(
            "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            leaflet.TileLayerOptions()
              .setMaxZoom(19)
              .setAttribution(
                """&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors"""
              )
          )
          .addTo(map)
        leaflet.marker(latLng).addTo(map)
        map.invalidateSize(true)
    }
  }

  private val map =
    tr(
      td(
        div(
          className := "w-100 m-0 overflow-hidden",
          height.px := 350,
          idAttr    := mapId,
          onMountCallback(_ => initMap())
        )
      )
    )

  private val featuring =
    Option(engagement.get.artists).filter(_.nonEmpty).map { a =>
      tr(
        th("Featuring"),
        td(a.map(_.get.name).mkString(", "))
      )
    }

  private val body =
    div(
      idAttr                := collapseId,
      className             := "accordion-collapse collapse " + Option.when(isFirst)("show").mkString,
      aria.labelledBy       := headerId,
      dataAttr("bs-parent") := "#accordionExample",
      div(
        className := "accordion-body",
        div(
          className := "row",
          flyer,
          div(
            className := "col",
            value.description.map(_.copy(typ = Markdown)).map(TextElement(_).render).toSeq,
            table(
              className := "table w-100",
              tbody(
                doors,
                stageTime,
                ticketPrice,
                tickets,
                tr(
                  th(rowSpan := 2, "Location"),
                  td(value.venue.get.address.map { addr =>
                    addressTag(
                      value.venue.get.name,
                      br(),
                      s"${addr.street} ${addr.streetNumber.mkString}${addr.streetNumberAddon.mkString}",
                      br(),
                      s"${addr.postalCode} ${addr.location} ${addr.state}"
                    ) ::
                      value.venue.get.electronicAddresses.map(_.get).filter(_.addressType == Website).map { e =>
                        a(href := e.value, target := "_blank", e.value)
                      }
                  }.get)
                ),
                map,
                featuring
              )
            )
          )
        )
      )
    )

  def render: ReactiveElement.Base =
    div(
      className := "accordion-item",
      header,
      body
    )

}
